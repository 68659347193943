(function ($, window, document, undefined) {
    "use strict";

    var pluginName = "autoComplete",
        defaults = {},
        me;

    function Plugin(element, options) {
        me = this,
            me.$element = $(element),
            me.settings = $.extend({}, defaults, options),
            me._defaults = defaults,
            me._name = pluginName;

        me.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            // $('input[data-auto-complete]').on('keydown', me.onAutoCompleteKeyDown).autocomplete(me.autoComplete);

            var $inputs = $('input[data-auto-complete]');
            $.each($inputs, function () {
                var $input = $(this);

                $input.tagsInput({
                    'autocomplete_url': '',
                    'autocomplete': {
                        source: $input.data('auto-complete'),
                        selectFirst:true,
                        autoFill:true
                    },
                    defaultText: '',
                    width:'100%',
                    minHeight: '',
                    height: ''
                });
            });
        },
        autoComplete: {
            minLength: 0,
            source: function (request, response) {
                var $input = this.element,
                    availableTags = $input.data('auto-complete');

                // delegate back to autocomplete, but extract the last term
                response($.ui.autocomplete.filter(
                    availableTags, me.extractLast(request.term)));
            },
            focus: function () {
                // prevent value inserted on focus
                return false;
            },
            select: function (event, ui) {
                var terms = me.split(this.value);
                // remove the current input
                terms.pop();
                // add the selected item
                terms.push(ui.item.value);
                // add placeholder to get the comma-and-space at the end
                terms.push("");
                this.value = terms.join(", ");
                return false;
            }
        },
        onAutoCompleteKeyDown: function (event) {
            if (event.keyCode === $.ui.keyCode.TAB &&
                $(this).autocomplete("instance").menu.active) {
                event.preventDefault();
            }
        },
        split: function (val) {
            return val.split(/,\s*/);
        },
        extractLast: function (term) {
            return me.split(term).pop();
        }
    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" +
                    pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window, document);
