(function ($, window, document, undefined) {
    "use strict";

    var pluginName = "ticketFilters",
        defaults = {},
        me;

    function Plugin(element, options) {
        me = this,
            me.$element = $(element),
            me.settings = $.extend({}, defaults, options),
            me._defaults = defaults,
            me._name = pluginName;

        me.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            $.fn.select2.defaults.set("theme", "bootstrap4");
            $('select.select2:not(.customer)').select2({
                width: '100%',
                theme: "bootstrap4"
            });
            $('select.select2.customer').select2({
                width: '100%',
                theme: "bootstrap4",
                matcher: me.customerMatcher
            });
            $('button[type="reset"]').on('click', me.onResetButtonClick);
            $('#toggle-filter-container').on('click', me.onToggleFiltersClick);
        },
        onToggleFiltersClick: function (event) {
            var $button = $(this);

            $.each($button.find('span'), function () {
                var $span = $(this);

                $span.toggleClass('d-none');
            });
        },
        onResetButtonClick: function (event) {
            event.preventDefault();

            var $form = $(this).closest('form'),
                $select2 = $form.find('select.select2'),
                $fields = $form.find('input, textarea, select');

            $fields.val('');
            $select2.trigger('change');
        },
        customerMatcher: function (params, data) {
            // Always return the object if there is nothing to compare
            if ($.trim(params.term) === '') {
                return data;
            }

            if ($(data.element).data('matchcode') !== undefined) {
                var matchCode = $(data.element).data('matchcode').toString().toLowerCase(),
                    searchTerm = params.term.toLowerCase();

                if (matchCode.indexOf(searchTerm) === 0) {
                    return data;
                }
            }

            // If it doesn't contain the term, don't return anything
            return null;
        }
    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" +
                    pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window, document);
