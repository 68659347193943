(function ($, window, document, undefined) {
  "use strict";
  
  var pluginName = "user2FA",
  defaults = {},
  me;
  
  function Plugin(element, options) {
    me = this,
    me.$element = $(element),
    me.settings = $.extend({}, defaults, options),
    me._defaults = defaults,
    me._name = pluginName;
    
    me.init();
  }
  
  $.extend(Plugin.prototype, {
    init: function () {
      $(document).on('click', '.user2fa-list tbody button.btResetUser2FA', me.setFirstLogin);
    },
    setFirstLogin: function (event) {
      

      let txtLoading = '<small class="px-1 py-3 text-secondary">Loading...</small>';
      let urlAPI = $('#url2FAUserRequest').val();
      let idUser = $(this).closest('tr').attr('data-node-id');

      let button = this;
      $(button)
        .prop('disabled',true)
        .addClass('d-none')
        .after(txtLoading);

      console.log('idUser',idUser);

      $.ajax({
        url: urlAPI,
        method: 'POST',
        dataType: 'json',
        data : {'idUser':idUser},
        success: function(data) {
          console.log(data,'printed');
          if($('.alert').length) $('.alert').remove();
          $('body').append(data.alert);
          $('#alertModal').modal('show');
          
          $(button)
            .prop('disabled',false)
            .removeClass('d-none')
            .next('small').remove();
        },
        error: function(xhr, status, error) {
          alert('Is not posible reset 2FA for the user selected');
          console.warn(xhr);
        }
      });
    }
  });
  
  $.fn[pluginName] = function (options) {
    return this.each(function () {
      if (!$.data(this, "plugin_" + pluginName)) {
        $.data(this, "plugin_" +
        pluginName, new Plugin(this, options));
      }
    });
  };
  
})(jQuery, window, document);