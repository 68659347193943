(function ($, window, document, undefined) {
    "use strict";

    var pluginName = "sidebar",
        defaults = {},
        me;

    function Plugin(element, options) {
        me = this,
            me.$element = $(element),
            me.settings = $.extend({}, defaults, options),
            me._defaults = defaults,
            me._name = pluginName;

        me.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            $(document).ready(me.onDocumentReady);
        },
        onDocumentReady: function (event) {
            $("#sidebar").mCustomScrollbar({
                // theme: "minimal"
            });

            $('#sidebarCollapse').on('click', function () {
                $('#sidebar, #content-wrapper, .overlay').toggleClass('active');
                $('.collapse.in').toggleClass('in');
                $('a[aria-expanded=true]').attr('aria-expanded', 'false');
            });
        }
    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" +
                    pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window, document);
